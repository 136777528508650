import * as React from 'react'

import { useFormik } from 'formik';
import { Popover, Transition } from '@headlessui/react'

import EmailService from '../../../services/emailService';

const validate = values => {

    const errors = {};

    if (!values.firstName.length) {
        errors.firstName = 'Required';
    }

    if (!values.email) {
        errors.email = 'Required';
    }

    if (!values.message) {
        errors.message = 'Required';
    }

    return errors;
}


export default function ContactForm({ SiteSettings }) {

    const [formSent, setFormSent] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: ''
        },
        validate,
        onSubmit: values => {


            EmailService.send(formRef).then( _ => setFormSent(true) )
        }
    })

    const formRef = React.createRef();


    return (
        <section className="bg-gray-50 py-12 border-t border-t-[#2a9d8f] pt-28">
            <div className="container px-4 mx-auto max-w-7xl">
                <div className="flex flex-wrap flex-col -m-3 mb-3">
                <div className="max-w-7xl block mx-auto py-8">
                    <h2 className="text-coolGray-900 text-7xl text-[#264653] font-semibold text-center">What's your big idea</h2>
                    <p className="text-3xl text-[#2a9d8f] text-center p-4 font-medium">Get in touch to bring you site to life</p>
                </div>


                <div className="w-full mx-auto md:w-3/4 p-3 bg-white border border-[#2a9d8f]">
                    <div className="p-6 flex-1 h-full overflow-hidden rounded-md">    
                    <form ref={formRef} onSubmit={formik.handleSubmit}>
                        <div className="flex flex-wrap pb-3 -m-3">
                            <div className="w-full md:w-1/2 p-3">
                                <p className="mb-1.5 font-medium text-base text-[#264653]">First Name *</p>
                                <input 
                                    className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-[#2a9d8f] border border-coolGray-200 rounded-lg shadow-input" 
                                    id="firstName" 
                                    name="firstName"
                                    type="text" 
                                    placeholder="First Name" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.firstName}
                                    style={{
                                        borderColor: formik.touched.firstName && formik.errors.firstName ? 'red' : ''
                                    }}
                                />
                                {formik.touched.firstName && formik.errors.firstName ? <div className="text-red-500">{formik.errors.firstName}*</div> : null}
                            </div>
                            <div className="w-full md:w-1/2 p-3">
                                <p className="mb-1.5 font-medium text-base text-[#264653]">Last name</p>
                                <input 
                                    className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-[#2a9d8f] border border-coolGray-200 rounded-lg shadow-input" 
                                    id="lastName"
                                    name="lastName" 
                                    type="text" 
                                    placeholder="Last Name" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.lastName}
                                />
                            </div>
                            <div className="w-full md:w-1/2 p-3">
                                <p className="mb-1.5 font-medium text-base text-[#264653]">Email *</p>
                                <input 
                                    className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-[#2a9d8f] border border-coolGray-200 rounded-lg shadow-input" 
                                    id="email" 
                                    name="email"
                                    type="text" 
                                    placeholder="Email" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    style={{
                                        borderColor: formik.touched.email && formik.errors.email ? 'red' : ''
                                    }}
                                />
                                {formik.touched.email && formik.errors.email ? <div className="text-red-500">{formik.errors.email}*</div> : null}
                            </div>
                            <div className="w-full md:w-1/2 p-3">
                                <p className="mb-1.5 font-medium text-base text-[#264653]">Phone number</p>
                                <input 
                                    className="w-full px-4 py-2.5 text-base text-coolGray-900 font-normal outline-none focus:border-[#2a9d8f] border border-coolGray-200 rounded-lg shadow-input" 
                                    id="phone" 
                                    name="phone"
                                    type="text" 
                                    placeholder="Phone Number" 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.phone}
                                />
                            </div>
                        </div>
                        <div className="py-3">
                            <div className="w-full">
                            <div className="flex flex-wrap">
                                <div className="w-full">
                                    <p className="mb-1 text-sm text-coolGray-800 font-semibold text-[#264653]">Message *</p>
                                    <textarea
                                        className="block w-full h-64 p-4 text-base text-coolGray-900 font-normal outline-none focus:border-[#2a9d8f] border border-coolGray-200 rounded-lg" 
                                        id="message" 
                                        name="message"
                                        type="textarea"
                                        onChange={formik.handleChange}
                                        value={formik.values.message}
                                        onBlur={formik.handleBlur}
                                        style={{
                                            borderColor: formik.touched.message && formik.errors.message ? 'red' : ''
                                        }}
                                    />
                                </div>
                                {formik.touched.message && formik.errors.message ? <div className="text-red-500">{formik.errors.message}*</div> : null}
                            </div>
                            </div>
                            <input id="to_email" name='to_email' type="hidden" value={SiteSettings.contactEmail?.value} />
                        </div>
                        <button 
                            className="flex flex-wrap justify-center w-full md:w-auto md:ml-auto px-4 py-2 bg-[#e76f51] hover:bg-gray-50 hover:text-[#e76f51] border hover:border-[#e76f51] font-medium text-sm text-white rounded-md shadow-button"
                            type='submit'
                        >
                            <p>Send Message</p>
                        </button>
                    </form>
                    </div>
                </div>

                <Transition
                    show={formSent}
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <div className="border border-gray-400 p-4 my-4 max-w-7xl mx-auto rounded md:w-3/4 bg-white">
                        <p className="text-2xl text-center text-[#264653]">Thank you for getting in touch. Salient Applications will be with you soon.</p>
                    </div>
                </Transition>

                </div>
            </div>
        </section>
    )
}