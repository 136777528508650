import * as React from 'react';

import * as Components from '../../pageSections/contact';
import Template from '../../pageSections/templates/secondary';

import { graphql } from 'gatsby';

export default function Home({ data }) {

    const [siteSettings, setSiteSettings] = React.useState({});

    React.useEffect(() => setSiteSettings(JSON.parse(data.datoCmsWebsite.settings)), []);

    return (
        <Template>
            {/* place your sections here from both Template and Page imports */}
            <Components.ContactForm SiteSettings={JSON.parse(data.datoCmsWebsite.settings)} />
        </Template>
    )
}


export const query = graphql`
    query ContactPageQuery($sitename: String) {
        datoCmsWebsite(name: { eq: $sitename }) {
            id
            name
            settings
        }
    }
`